import React from 'react'
import Typeform from '../../../components/Integrations/IntegrationsDetails/Typeform'
import PageSeo from '../../../components/SEO'

function index() {
    return (
        <div>
              <PageSeo pageTitle="Typeform"/>
              <Typeform/>
        </div>
    )
}

export default index
